<template>
    <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="row row-sm mg-t-10 mg-b-10">
            <div class="col-9">
                <h2 class="az-content-title tx-24 mg-b-5">Provenance</h2>
            </div>
            <div class="col-3" v-if="isFirstStepAttestor">
                <router-link :to="{name: 'new-process'}" class="d-none d-xl-block float-right btn btn-orange rounded font-weight-bold" title="Add Process">
                    <i class="fas fa-plus-square mg-r-10" />Start New Process
                </router-link>
                <button class="d-xl-none btn btn-icon float-right w-35 btn-orange rounded" title="Add Process">
                    <i class="typcn typcn-document-add" />
                </button>
            </div>
        </div>

        <p class="mg-b-40" v-html="$DEFINITIONS.provenance.about"></p>
        <div class="card widget-inline">
            <div class="card-body  d-lg-none d-md-none" v-if="removeLoader">
                <div class="row text-center">
                    <div class="col-3">
                        <i class="fas fa-list-alt text-muted"></i>
                        <p class="font-15 mb-0">{{awaitingProcessSteps+ongoingProcessSteps+signedProcessSteps}}</p>
                        <h6 class="text-truncate d-block mb-0">Total</h6>
                    </div>
                    <div class="col-3">
                        <i class="fas fa-hourglass text-danger"></i>
                        <p class="font-15 mb-0">{{awaitingProcessSteps}}</p>
                        <h6 class="text-truncate d-block mb-0">Awaiting</h6>
                    </div>
                    <div class="col-3">
                        <i class="fas fa-clipboard text-primary"></i>
                        <p class="font-15 mb-0">{{ongoingProcessSteps}}</p>
                        <h6 class="text-truncate d-block mb-0">Primary</h6>
                    </div>
                    <div class="col-3">
                        <i class="fas fa-thumbs-up text-success"></i>
                        <p class="font-15 mb-0">{{signedProcessSteps}}</p>
                        <h6 class="text-truncate d-block mb-0">Signed</h6>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 d-none d-sm-block" v-if="removeLoader">
                <div class="row no-gutters">
                    <div class="col-sm-6 col-xl-3">
                        <div class="card shadow-none m-0">
                            <div class="card-body text-center">
                                <i class="fas fa-list-alt text-muted" style="font-size: 24px;"></i>
                                <h3><span>{{awaitingProcessSteps+ongoingProcessSteps+signedProcessSteps}}</span></h3>
                                <p class="text-muted font-15 mb-0">Total Process Steps</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-xl-3">
                        <div class="card shadow-none m-0 border-left">
                            <div class="card-body text-center">
                                <i class="fas fa-hourglass text-danger" style="font-size: 24px;"></i>
                                <h3><span>{{awaitingProcessSteps}}</span></h3>
                                <p class="text-muted font-15 mb-0">Awaiting Process Steps</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-xl-3">
                        <div class="card shadow-none m-0 border-left">
                            <div class="card-body text-center">
                                <i class="fas fa-clipboard text-primary" style="font-size: 24px;"></i>
                                <h3><span>{{ongoingProcessSteps}}</span></h3>
                                <p class="text-muted font-15 mb-0">Primary Process Steps</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-xl-3">
                        <div class="card shadow-none m-0 border-left">
                            <div class="card-body text-center">
                                <i class="fas fa-thumbs-up text-success" style="font-size: 24px;"></i>
                                <h3><span>{{signedProcessSteps}}</span></h3>
                                <p class="text-muted font-15 mb-0">Signed Process Steps</p>
                            </div>
                        </div>
                    </div>
                </div> <!-- end row -->
            </div>
            <div class="d-flex justify-content-center mt-3 mb-3" v-else>
                <div class="spinner-border avatar-md text-primary" role="status"></div>
            </div>
        </div> <!-- end card-box-->
        <div class="row row-sm mg-t-20 mg-b-20" v-if="total_tasks>0">
            <div class="col-lg-7">
                <div class="card card-dashboard-pageviews">
                    <div class="card-body">
                        <div class="dropdown float-right mx-sm-2">
                            <select class="custom-select custom-select-sm" id="status-select" v-model="filter_task_status">
                                <option value="Primary">Primary</option>
                                <option value="Awaiting">Awaiting</option>
                                <option value="Signed">Signed</option>
                            </select>
                        </div>
                        <h6 class="card-title">Process Steps</h6>
                        <p v-if="filter_task_status === 'All' && tasks.length>0"><b>{{total_tasks}}</b> Total tasks</p>
                        <p v-if="filter_task_status === 'Primary' && tasks.length>0"><b>{{primary_tasks}}</b>&nbsp;Primary tasks</p>
                        <p v-if="filter_task_status === 'Awaiting' && tasks.length>0"><b>{{awaiting_tasks}}</b> Awaiting tasks</p>
                        <p v-if="filter_task_status === 'Signed' && tasks.length>0"><b>{{signed_tasks}}</b> Completed tasks</p>
                        <div @click="toStep(task)" class="az-list-item custom-div-hover" v-for="(task, index) in tasks" v-if="tasks.length>0 && removeTaskLoader">
                            <div>
                                <h6 class="custom-label-font custom-text-body">{{task.name}}</h6>
                                <span class="text-muted font-13"
                                      v-if="task.step && task.step.order >= task.current_step && task.due === 0">Arrived today</span>
                                <span class="text-muted font-13"
                                      v-else-if="task.step && task.step.order >= task.current_step && task.due > 0">Due in {{task.due}} days</span>
                            </div>
                            <div>
                                <span>Assigned to</span>
                                <h6 class="custom-text-body custom-label-font" v-if="task.step">{{task.step.name}}</h6>
                            </div>
                            <div>
                                <h6 class="custom-text-body custom-label-font">Signing Status</h6>
                                <span class="badge badge-pill badge-primary text-white"
                                      v-if="task.step && task.step.order === task.current_step">Primary</span>
                                <span class="badge badge-pill badge-success text-white"
                                      v-else-if="task.step && task.step.order < task.current_step">Signed</span>
                                <span class="badge badge-pill badge-danger text-white"
                                      v-else>Awaiting</span>
                            </div>
                        </div><!-- list-group-item -->
                        <div class="d-flex justify-content-center mb-3" v-if="!removeTaskLoader">
                            <div class="spinner-border avatar-md text-primary" role="status"></div>
                        </div>
                        <div v-if="tasks.length===0 && removeTaskLoader">
                            <h4 class="card-title text-muted text-center mt-lg-4">No records found.</h4>
                            <h5 class="card-title text-muted text-center mb-lg-4">Change search criteria and try
                                again</h5>
                        </div>
                    </div>
                    <hr>
                    <pagination :page="page" :total="total" @pagechange="handlePagerChange"/>
                </div><!-- card -->

            </div><!-- col -->
            <div class="col-lg-5 mg-t-20 mg-lg-t-0">
                <div class="card card-dashboard-four">
                    <div class="card-header">
                        <h6 class="card-title">Signing Status</h6>
                    </div><!-- card-header -->
                    <div class="card-body row" v-if="removeLoader">
                        <apexchart :options="chartOptions" :series="series" type="donut"></apexchart>
                    </div><!-- card-body -->

                    <div class="d-flex justify-content-center mb-3" v-else>
                        <div class="spinner-border avatar-md text-primary" role="status"></div>
                    </div>
                </div><!-- card-dashboard-four -->
            </div><!-- col -->
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import moment from "moment";
    import Pagination from "../../Pagination";

    export default {
        name: "ProvenanceHome",
        components: {Pagination},
        computed: {
            ...mapGetters(["currentUser"])
        },
        watch: {
            "filter_task_status": function (nv, ov) {
                if (nv) {
                    if (nv === 'Primary') {
                        this.getPrimaryTasks(0);
                    } else if (nv === 'Awaiting') {
                        this.getAwaitingTasks(0);
                    } else if (nv === 'Signed') {
                        this.getSignedTasks(0);
                    }
                }
            }
        },
        data() {
            return {
                isFirstStepAttestor: false,
                process_definition: null,
                attester_steps: [],
                tasks: [],
                filter_task_status: 'Primary',
                total_tasks: 0,
                primary_tasks: 0,
                signed_tasks: 0,
                awaiting_tasks: 0,
                page: 0,
                perPage: localStorage.getItem('pageSize') || 5,
                total: 0,
                series: [],
                chartOptions: {
                    labels: ['Primary', 'Signed', 'Awaiting'],
                    dataLabels: {
                        formatter: function (val) {
                            return ''
                        },
                    }
                },
                removeLoader: false,
                removeTaskLoader: false,
                ongoingProcessSteps: 0,
                awaitingProcessSteps: 0,
                signedProcessSteps: 0
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await this.getAvailableDefinition();
                if(!this.process_definition){
                    await this.getInProgressDefinition();
                }
                if(!this.process_definition){
                    await this.getCompleteProcessCount();
                }
                await this.getProcessDefinitionSteps();
                await Promise.all([
                        this.getOngoingProcessSteps(),
                        this.getAwaitingProcessSteps(),
                        this.getSignedProcessSteps()
                ]);
                await Promise.all([
                    this.getPrimaryTasks(0),
                    this.getAwaitingTasks(0),
                    this.getSignedTasks(0)
                ]).then(res => {
                    this.total_tasks = Number(this.primary_tasks) + Number(this.awaiting_tasks) + Number(this.signed_tasks);
                    this.series[0] = this.primary_tasks;
                    this.series[1] = this.signed_tasks;
                    this.series[2] = this.awaiting_tasks;
                    this.removeLoader = true;
                }).catch(e => {
                    this.removeLoader = true;
                })
            },
            async getOngoingProcessSteps() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/ongoing_process_steps`);
                    this.ongoingProcessSteps = data && data.length > 0 ? data.length : 0;
                }catch (e){

                }
            },
            async getAwaitingProcessSteps() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/awaiting_process_steps`);
                    this.awaitingProcessSteps = data && data.length > 0 ? data.length : 0;
                }catch (e){

                }
            },
            async getSignedProcessSteps() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/signed_process_steps`);
                    this.signedProcessSteps = data && data.length > 0 ? data.length : 0;
                }catch (e){

                }
            },
            async getAvailableDefinition() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/available_definitions?page=0&per_page=100`);
                    this.process_definition = data.process_definitions.length > 0 ? data.process_definitions[0] : null;
                } catch (e) {

                }
            },
            async getInProgressDefinition() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/processes_in_progress?page=0&per_page=100`);
                    this.process_definition = data.processes.length > 0 ? data.processes[0] : null;
                } catch (e) {

                }
            },
            async getCompleteProcessCount() {
                try {
                    let { data } = await this.$provenance.get(`provenance/registries/processes_completed?page=0&per_page=100`);
                    this.process_definition = data.processes.length > 0 ? data.processes[0] : null;
                } catch (e) {

                }
            },
            async getProcessDefinitionSteps() {
                if (this.process_definition) {
                    try {
                        let {data} = await this.$provenance.get(`provenance/registries/${this.process_definition.registry}/definitions/${this.process_definition.process_definition}/definition_steps?page=0&per_page=100`);
                        let that = this;
                        let found = data.process_definition_steps.find(ts => ts.order === 0);
                        let order_0_attestors = found ? found.attestors : [];
                        this.isFirstStepAttestor = order_0_attestors.length>0 ? order_0_attestors.findIndex(a => a.did === this.currentUser.did) != -1 : false;
                        this.attester_steps = data.process_definition_steps.filter(s => s.attestors.some(function (sa) {
                            return sa.did === that.currentUser.did
                        }));
                        console.log(this.attester_steps)
                    } catch (e) {

                    }
                }
            },

            async getPrimaryTasks(page) {
                if (this.attester_steps.length > 0) {
                    try {
                        this.tasks = [];
                        this.removeTaskLoader = false;
                        let {data} = await this.$provenance.get(`provenance/registries/${this.process_definition.registry}/definitions/${this.process_definition.process_definition}/processes`, {
                            params: {
                                page: page,
                                per_page: this.perPage,
                                from_step: this.attester_steps[0].order,
                                to_step: this.attester_steps[this.attester_steps.length - 1].order,
                            }
                        });
                        this.primary_tasks = data.total;
                        if (this.filter_task_status === 'Primary') {
                            let tasks = data.processes;
                            let datas = await Promise.all(
                                    tasks.map(task => {
                                        let attester_step = this.attester_steps.find(as => as.order === task.current_step);
                                        if (attester_step) {
                                            return this.$provenance.get(`provenance/registries/${this.process_definition.registry}/definitions/${this.process_definition.process_definition}/processes/${task.process}/process_steps/by_process_definition_step`, {
                                                params: {
                                                    process_definition_step: attester_step.process_definition_step
                                                }
                                            });
                                        }

                                    })
                            );
                            this.tasks = tasks.map((t, index) => {
                                let reply = datas ? datas[index] : null;
                                let data = reply ? reply.data : null;
                                let step = null;
                                if (data) {
                                    data.name = data.process_definition_step_name;
                                    step = data;
                                }
                                return {
                                    ...t,
                                    step: step,
                                    due: data ? moment(new Date().toISOString().substring(0, 10)).diff(moment(data.created.substring(0, 10)), "days") : null
                                }
                            });
                            this.total = data ? data.total : 0;
                        }
                        this.setPageCount();
                    } catch (e) {
                        console.error(e)
                    } finally {
                        this.removeTaskLoader = true;
                    }
                }
            },
            async getAwaitingTasks(page) {
                if (this.attester_steps.length > 0) {
                    try {
                        this.tasks = [];
                        this.removeTaskLoader = false;
                        let {data} = await this.$provenance.get(`provenance/registries/${this.process_definition.registry}/definitions/${this.process_definition.process_definition}/processes`, {
                            params: {
                                page: page,
                                per_page: this.perPage,
                                to_step: this.attester_steps[0].order
                            }
                        });
                        this.awaiting_tasks = data.total;
                        if (this.filter_task_status === 'Awaiting') {
                            let tasks = data.processes;
                            this.tasks = tasks.map(t => ({
                                ...t,
                                step: this.attester_steps.find(as => as.order > t.current_step),
                                due: moment(new Date().toISOString().substring(0, 10)).diff(moment(t.created.substring(0, 10)), "days")

                            }));
                            console.log("LOL", this.tasks)
                            this.total = data.total;
                        }
                        this.setPageCount();
                    } catch (e) {
                        console.error(e)
                    } finally {
                        this.removeTaskLoader = true;
                    }
                }
            },
            async getSignedTasks(page) {
                if (this.attester_steps.length > 0) {
                    try {
                        this.tasks = [];
                        this.removeTaskLoader = false;
                        let datas = await Promise.all(
                                this.attester_steps.map((task, index) => {
                                    return this.$provenance.get(`provenance/registries/${this.process_definition.registry}/definitions/${this.process_definition.process_definition}/processes`, {
                                        params: {
                                            page: 0,
                                            per_page: 100,
                                            from_step: this.attester_steps[index].order + 1
                                        }
                                    });

                                })
                        );
                        let tasks = [];
                        this.attester_steps.forEach((as, index) => {
                            let {data} = datas[index];
                            let task = data.processes.map(p => {
                                return {
                                    ...p,
                                    step: as
                                }
                            });
                            tasks = tasks.concat(task);
                        });
                        this.signed_tasks = tasks.length;
                        if (this.filter_task_status === 'Signed') {
                            this.tasks = tasks;
                            this.total = tasks.length;
                            let start = (page) * this.perPage;
                            let end = Number(start) + Number(this.perPage);
                            this.tasks = tasks.slice(start, end);
                            this.setPageCount()
                        }
                        this.setPageCount();
                    } catch (e) {

                    } finally {
                        this.removeTaskLoader = true;
                    }
                }
            },
            async handlePagerChange(e) {
                this.page = e.page;
                this.perPage = e.per_page;
                this.tasks = [];
                this.removeTaskLoader = false;
                if (this.filter_task_status === 'Primary') {
                    await this.getPrimaryTasks(this.page);
                } else if (this.filter_task_status === 'Awaiting') {
                    await this.getAwaitingTasks(this.page);
                } else if (this.filter_task_status === 'Signed') {
                    await this.getSignedTasks(this.page);
                }
            },
            setPageCount() {
                this.pageCount = this.total <= this.perPage ? 1 : Math.ceil(this.total / this.perPage);
            },
            toStep(task) {
                this.$router.push({
                    name: 'ongoing-process-steps', params: {
                        processid: task.process,
                        registryid: task.registry,
                        processdefinitionid: task.process_definition
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .custom-text-body {
        color: #596882 !important;
    }

    .custom-label-font {
        font-size: 13px;
    }

    .custom-div-hover:hover {
        color: #031b4e;
        cursor: pointer;
    }
</style>